.homePage .background {
  background-image: url("../public/images/home-background.webp");
}

.homePage .container {
  color: white;
}
.homePage .content {
  max-width: 720px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 1rem;
}

nav {
  position: fixed;
  width: 100%;
  /* background-color: black; */
  vertical-align: middle;
}

.nav-links {
  overflow: hidden;
  max-height: 0;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: black;
  transition: max-height 0.3s ease-in-out;
}

.nav-links.open {
  max-height: 500px;
  display: flex;
  flex-direction: column;
}

.nav-links li a {
  display: block;
  font-weight: 700;
  font-size: 20px;
  padding: 0.5rem 0;
  border-top: 1px solid rgb(220, 70, 68);
}
.nav-links li .active {
  color: white;
}
.menu-icon {
  display: flex; /* Show the menu icon by default */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  font-size: 32px;
  height: 100%;
  background-color: black;
  padding: 0.5rem 0;
}

/* Ensure the nav-links are visible on wider screens */
@media (min-width: 769px) {
  .nav-links {
    display: block; /* Always show the nav links on wider screens */
    max-height: none;
  }

  .menu-icon {
    display: none; /* Hide the menu icon on wider screens */
  }

  /* Adjustments to nav-links for desktop layout */
  .nav-links {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
    margin: 0;
  }

  .nav-links li a {
    font-weight: 700;
    padding: 0.25rem 1rem;
    transition: all 300ms ease;
    border-radius: 3px;
    border: none;
    margin: 0 0.5rem;
  }

  .nav-links li a:hover {
    background-color: white;
    color: black;
  }

  .nav-links li .active {
    background-color: white;
    color: black;
  }
}

.footer {
  position: fixed;
  display: flex;
  width: 100%;
  height: 36px;
  background-color: black;
  color: white;
  bottom: 0;
  justify-content: center;
  align-items: center;
}
